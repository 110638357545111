import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { kebabCase } from 'lodash'

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Box,
  Heading,
} from '@chakra-ui/react'

import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import Section from '../components/layout/Section'
import SEO from '../components/elements/SEO'
import Hero from '../components/elements/Hero'

const ResourcesPage = ({ data }) => {
  const page = data.prismicResourcespage
  const faqs = data.prismicResourcespageBodyFaqs

  return (
    <Layout>
      <SEO
        meta_title={page.data.meta_title}
        meta_description={page.data.meta_description}
      />
      <Hero>
        <BackgroundImage
          Tag="div"
          fluid={page.data.hero_image.fluid}
          css={`
            padding-top: 180px;
          `}
        >
          <Container py={['12em', '12em', '16em']} />
        </BackgroundImage>
      </Hero>

      <Section id="intro">
        <Container>
          <Flex flexWrap="wrap">
            <Box width={['full', 'full', 3 / 4]}>
              <Heading as="h1">{page.data.title.text}</Heading>
              <div
                dangerouslySetInnerHTML={{
                  __html: page.data.content.html,
                }}
              />
            </Box>
          </Flex>
        </Container>
      </Section>

      <Section id="faqs" bg="gray.50">
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">
            <Box width={['full']} textAlign="center">
              <Heading as="h2">FAQs</Heading>
            </Box>

            <Accordion bg="white" width={['full', 'full', 2 / 3]} allowMultiple>
              {faqs.items.map(({ question, answer }) => (
                <AccordionItem key={`${kebabCase(question.text)}`}>
                  <AccordionButton>
                    <Box flex="1" textAlign="left" as="h4" m={3}>
                      {question.text}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel px={7} py={3}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: answer.html,
                      }}
                    />
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default ResourcesPage

export const query = graphql`
  query ResourcesPage {
    prismicResourcespage {
      data {
        meta_description
        meta_title
        content {
          html
        }
        hero_image {
          url
          fluid(maxWidth: 1500) {
            ...GatsbyPrismicImageFluid
          }
        }
        title {
          text
        }
      }
    }
    prismicResourcespageBodyFaqs {
      id
      items {
        question {
          text
        }
        answer {
          html
        }
      }
    }
  }
`
